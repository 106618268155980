import * as React from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

const Testimonials = ({ testimonials }) => (
  <div>
    {testimonials.map((testimonial) => (
      <article key={v4()} className="message is-medium is-success">
        <div className="message-body">
          <h4><cite> – {testimonial.name}</cite></h4>
          {testimonial.product}
          <div>{testimonial.description}</div>
          <div>{testimonial.location}</div>
          <div>{testimonial.quote}</div>
        </div>
      </article>
    ))}
  </div>
);

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      product: PropTypes.string,
      location: PropTypes.string,
      quote: PropTypes.string
    })
  ),
};

export default Testimonials;

import * as React from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map((item, index) => (
        <div id={`programme${index}`} key={v4()} className="column is-12">
            <div className="product-item" style={{border: "1px double #bfdaf0"}}>
                <div className="has-text-centered">
                    <h2 className="item-heading">{item.heading} { item.updates && (<span className="item-red-highlight">&nbsp;{item.updates}</span>)}</h2>
                    {item.summary && 
                        (
                            <div className="item-summary">
                                <div>{item.summary}</div>
                                <div>{item.summary1}</div>
                                <div>{item.summary2}</div>
                                <div>{item.summary3}</div>
                                <div>{item.summary4}</div>
                            </div>
                        )
                    }
                    <div className="column">
                        <div className="notification is-info has-text-weight-semibold" style={{border: "1px solid #99d5ed", margin: "0.25rem auto",padding: "0.35rem", maxWidth: "31rem"}}><strong>Jetzt bestellen: </strong><b><abbr title="copy und paste email">gastrokalk@gmail.com</abbr></b></div>
                    </div>
                    <div>
                        <div className="column is-full" dangerouslySetInnerHTML={{__html: item.video }} />
                    </div>
                    <div>
                        {(item.salePrice && 
                            (
                                <div>
                                    <h3><span style={{textDecoration: "line-through", color: "#c2b7b7"}}>{item.price}</span> Jetzt nur: <span className="item-red-highlight">{item.salePrice}</span> Sie Sparen: <span className="item-red-highlight" style={{fontWeight: "700", textDecoration: "underline"}}>{item.savings}</span>*</h3> 
                                    <div>*Sale Preis endet: {item.expires}</div>
                                </div>
                            ))
                        ||
                            (<h3>Nur: {item.price}</h3>)
                        }
                    </div>
                    <div className="tile is-ancestor">
                        <div className="tile is-vertical">
                            <div className="tile">
                                <div className="tile is-parent is-vertical">
                                    {item.bulletsInhalt &&
                                        <article className="tile is-child">
                                            <h4>{item.bulletsInhaltHeading}</h4>
                                            <hr />
                                            <ul style={{textAlign: "initial",}}>
                                                {item.bulletsInhalt?.map((bullet) => (
                                                    <li key={bullet}>{bullet}</li>  
                                                ))}
                                            </ul>
                                        </article>
                                    }
                                </div>
                                <div className="tile is-parent">
                                    {item.bulletsKann &&
                                        <article className="tile is-child">
                                            <h4>{item.bulletsKannHeading}</h4>
                                            <hr />
                                            <ul style={{textAlign: "initial",}}>
                                                {item.bulletsKann?.map((bullet, index) => (
                                                    <li key={bullet}>{bullet}</li>  
                                                ))}
                                            </ul>
                                        </article>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {item.Systemanforderungen &&
                <section className="section">
                    <article>
                        <h4>Systemanforderungen - Fakten für alle Programme</h4>
                        <ul style={{textAlign: "initial",}}>
                            {item.Systemanforderungen.map((bullet) => (
                                <li key={bullet}>{bullet}</li>  
                            ))}
                        </ul>
                    </article>
                </section>
            }
        </div>
    ))}
  </div>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      heading: PropTypes.string,
      video: PropTypes.string,
      price: PropTypes.string,
      salePrice: PropTypes.string,
      savings: PropTypes.string,
      expires: PropTypes.string,
      updates: PropTypes.string,
      bulletsInhaltHeading: PropTypes.string,
      bulletsInhalt: PropTypes.array,
      bulletsKannHeading: PropTypes.string,
      bulletsKann: PropTypes.array,
      Systemanforderungen: PropTypes.array,
    })
  ),
};

export default FeatureGrid;
